import { parseToken } from '@/service/jwt';

export default {
  namespaced: true,
  state: () => ({
    tokenPayload: null,
    token: null,
    redirectPath: null
  }),
  getters: {
    getUserRole: (state) => state.tokenPayload?.authorities,
    getToken: (state) => state.token,
    isAuthenticated: (state) => !!state.tokenPayload,
    getRedirectPath: (state) => state.redirectPath
  },
  actions: {
    authenticate: async ({ dispatch }, payload) => {
      const params = {
        path: 'login',
        method: 'POST',
        body: JSON.stringify(payload),
      };

      const res = await dispatch('apiRequest', params, { root: true });

      if (res.status === 302) {
        throw new Error('MFA Required');
      }

      const data = await res.json();

      if (!data?.token) {
        throw new Error('Invalid credentials');
      }

      await dispatch('initAuth', data.token);
    },
    initAuth: async ({ commit, state }, token) => {
      if (token) {
        const payload = await parseToken(token);
        commit('SET_TOKEN', token);
        commit('SET_TOKEN_PAYLOAD', payload);
        return;
      }

      if (state.token) {
        const payload = await parseToken(state.token);
        commit('SET_TOKEN_PAYLOAD', payload);
        return;
      }
    },
    logOut: ({ commit }) => {
      localStorage.removeItem('credentials');
      commit('LOGOUT');
    },
    setRedirectPath: ({ commit }, path) => {
      commit('SET_REDIRECT_PATH', path);
    }
  },
  mutations: {
    'SET_TOKEN': (state, token) => {
      state.token = token;
    },
    'SET_TOKEN_PAYLOAD': (state, payload) => {
      state.tokenPayload = payload;
    },
    'LOGOUT': (state) => {
      state.tokenPayload = null;
      state.token = null;
    },
    'SET_REDIRECT_PATH': (state, path) => {
      state.redirectPath = path;
    }
  }
};
