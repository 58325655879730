export default {
  namespaced: true,
  state: {
    services: null
  },
  actions: {
    fetchServices: async ({ dispatch, commit }) => {
      const requestParams = { path: 'services', method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      const services = await response.json();
      commit('SET_SERVICES', services);
    }
  },
  mutations: {
    'SET_SERVICES': (state, services) => {
      state.services = services;
    }
  },
  getters: {
    services: (state) => state.services
  }
};
