<template>
  <i
    ref="tooltip-toggler"
    :title="tooltipText"
    data-bs-toggle="tooltip"
    :class="iconClass"
    role="button"
  />
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'iconClass', 'tooltipText' ],
  data() {
    return {
      tooltip: null
    };
  },
  mounted() {
    this.tooltip = new Tooltip(this.$refs['tooltip-toggler']);
  }
};
</script>
