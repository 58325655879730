export default {
  apiBase: process.env.VUE_APP_BASE_URL,
  supportPageUrl: process.env.VUE_APP_SUPPORT_PAGE_URL || 'https://outersp.atlassian.net/servicedesk/customer/portals',
  pusher: {
    channel: process.env.VUE_APP_PUSHER_CHANNEL || '',
    eventName: process.env.VUE_APP_PUSHER_EVENT_NAME || '',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER || '',
    appKey: process.env.VUE_APP_PUSHER_APP_KEY || ''
  },
  brandLogoCDN: process.env.VUE_APP_BRAND_LOGO_CDN || 'http://d2ddcpdjfmmqjr.cloudfront.net/',
};
