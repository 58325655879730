<template>
  <ul class="d-inline-flex gap-1 w-100 navbar-nav me-auto mb-0">
    <a
      :ref="`clps-${elementId}`"
      data-bs-toggle="collapse"
      :href="`#${elementId}`"
      role="button"
      :aria-expanded="!isElementCollapsed"
      :aria-controls="elementId"
      class="text-decoration-none nav-link w-100 d-flex justify-content-between"
      :class="{collapsed: isElementCollapsed}"
    >
      <div>
        <slot name="item-header" />
      </div>
      <span class="dropdown-toggle rotated animated" />
    </a>
  </ul>
  <div
    :id="elementId"
    class="bg-dark w-100 collapse"
    :class="{show: !isElementCollapsed}"
  >
    <hr class="m-0 p-0 text-light" />
    <div class="card card-body bg-dark border-0">
      <slot name="item-content" />
    </div>
    <hr class="m-0 p-0 text-light" />
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: ['isCollapsed'],
  data() {
    return {
      collapseObj: null,
      id: _.uniqueId()
    };
  },
  computed: {
    elementId() {
      return `collapse_${this.id}`;
    },
    isElementCollapsed() {
      return this.isCollapsed !== false;
    }
  },
};
</script>
<style>

.collapsed .rotated {
  animation:arrow-rotated-back 0.3s linear both;
}

@keyframes arrow-rotated-back {
  100% { rotate: -90deg; }
}

</style>
