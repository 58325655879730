import _ from 'lodash';
import { userRoles } from '@/components/constants';
import { routes } from '@/router/routes';

const getRoutesForRole = (role) => {
  return _.filter(routes, (r) => r.meta.allowedUserRoles.includes(role));
};

function hasRequiredPrivileges(routePrivileges, userPrivileges) {
  if (!routePrivileges) return false;
  return routePrivileges.every((privilege) => userPrivileges.includes(privilege));
}

const roleToRouteMap = {
  [`${userRoles.superAdmin}`]: getRoutesForRole(userRoles.superAdmin),
  [`${userRoles.admin}`]: getRoutesForRole(userRoles.admin),
  [`${userRoles.opsTeam}`]: getRoutesForRole(userRoles.opsTeam),
  [`${userRoles.accountTeam}`]: getRoutesForRole(userRoles.accountTeam),
  [`${userRoles.viewer}`]: getRoutesForRole(userRoles.viewer),
  [`${userRoles.manager}`]: getRoutesForRole(userRoles.manager),
  [`${userRoles.planner}`]: getRoutesForRole(userRoles.planner),
};

export const validateRouteAccess = (routeName, userRole) => {
  if (routeName === 'auth') {
    return true;
  }
  if (!userRole) {
    return false;
  }
  const authorities = userRole.split(',');
  const roleRoutes = [];
  authorities.forEach((role) => {
    const routes = roleToRouteMap[role];
    if (routes) {
      roleRoutes.push(...routes);
    }
  });

  const accessibleByPrivilegeRoutes = routes.filter((route) =>
    hasRequiredPrivileges(route.meta?.allowedUserPrivileges, authorities)
  );
  if (accessibleByPrivilegeRoutes) {
    roleRoutes.push(...accessibleByPrivilegeRoutes);
  }

  if (!roleRoutes) {
    return false;
  }

  return _.find(roleRoutes, [ 'name', routeName ]);
};

export const validateSectionAccess = (sectionName, userRole) => {
  const roles = userRole.split(',');
  const roleRoutes = [];
  roles.forEach((role) => {
    const routes = roleToRouteMap[role];
    if (routes) {
      roleRoutes.push(...routes);
    }
  });

  if (!roleRoutes) {
    return false;
  }

  return _.find(roleRoutes, [ 'meta.menuSection', sectionName ]);
};
