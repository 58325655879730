export default {
  namespaced: true,
  state: () => ({
    dashboardTypes: [],
    companyItems: []
  }),
  getters: {
    dashboardTypes: (state) => state.dashboardTypes,
    companyItems: (state) => state.companyItems
  },
  actions: {
    createDashboard: async ({ dispatch }, data) => {
      const formData = new FormData();
      formData.append('logo', data.brandLogo);
      formData.append('tableauDTO', JSON.stringify({
        dashboardName: data.dashboardName,
        brandCode: data.brandCode,
        templateId: data.dashboardId,
        projectId: data.projectId,
        databaseType: data.databaseType,
        brandId: data.brandId
      }));

      const params = {
        path: 'tableau/dashboard',
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': undefined
        }
      };

      const result = await dispatch('apiRequest', params, { root: true });
      return await result.json();
    },
    fetchAllDashboardTypes: async ({ dispatch, commit }) => {
      const params = {
        path: 'tableau/templates',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_DASHBOARD_TYPES', await response.json());
    },
    fetchCompanyItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'tableau/companies', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_COMPANY_ITEMS', rows);
    },
    fetchWorkbookByContentUrl: async ({ dispatch }, contentUrl) => {
      const params = {
        path: `tableau/workbook/${contentUrl}`,
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      return  await response.json();
    },
    fetchViewById: async ({ dispatch }, id) => {
      const params = {
        path: `tableau/view/${id}`,
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      return  await response.json();
    },
    createTableauUser: async ({ dispatch }, data) => {
      const params = {
        path: 'tableau/user',
        method: 'POST',
        body: JSON.stringify(data),
      };
      await dispatch('apiRequest', params, { root: true });
    },
    updateTableauUserClientGroup: async ({ dispatch }, data) => {
      const params = {
        path: 'tableau/user/group',
        method: 'POST',
        body: JSON.stringify(data),
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteTableauUser: async ({ dispatch }, data) => {
      const params = {
        path: 'tableau/user',
        method: 'DELETE',
        body: JSON.stringify(data),
      };
      await dispatch('apiRequest', params, { root: true });
    },
    updateTableauUserGroups: async ({ dispatch }, data) => {
      const params = {
        path: 'tableau/user/group',
        method: 'PUT',
        body: JSON.stringify(data),
      };
      await dispatch('apiRequest', params, { root: true });
    },
  },
  mutations: {
    'SET_DASHBOARD_TYPES': (state, templates) => {
      state.dashboardTypes = templates;
    },
    'SET_COMPANY_ITEMS': (state, items) => {
      state.companyItems = items;
    }
  }
};
