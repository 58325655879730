export default {
  namespaced: true,
  state: () => ({
    ukgMappingItems: [],
    bulkUploadConfig: {
      name: 'UKG Mapping',
      uploadUrl: '/ukg-mapping/upload',
      downloadUrl: '/ukg-mapping/download'
    },
    ukgMappingByBrand: []
  }),
  getters: {
    ukgMappingItems: (state) => state.ukgMappingItems,
    bulkUploadConfig: (state) => state.bulkUploadConfig,
    ukgMappingByBrand: (state) => state.ukgMappingByBrand,
  },
  actions: {
    fetchUKGMappingItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'ukg-mapping', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_UKG_MAPPING_ITEMS', rows);
    },
    fetchUKGMappingItemById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `ukg-mapping/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    createItem: async ({ dispatch }, item) => {
      const params = {
        path: 'ukg-mapping',
        method: 'POST',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    updateItem: async ({ dispatch }, item) => {
      const params = {
        path: `ukg-mapping/${item.id}`,
        method: 'PUT',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    deleteItem: async ({ dispatch }, id) => {
      const params = {
        path: `ukg-mapping/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
    uploadUkgMappingFile: async ({ dispatch }, data) => {
      const params = {
        path: 'ukg-mapping/upload',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchUKGMappingByBrand: async ({ dispatch, commit }, brandId) => {
      try {
        const res = await dispatch('apiRequest', { path: `ukg-mapping/brand/${brandId}`, method: 'GET' }, { root: true });
        const rows = await res.json();
        commit('SET_UKG_MAPPING_ITEM_BY_BRAND', rows);
      }
      catch (error){
        if (error.cause.errorDetails.status === 404){
          commit('SET_UKG_MAPPING_ITEM_BY_BRAND', null);
        }
      }
    },
  },
  mutations: {
    SET_UKG_MAPPING_ITEMS: (state, items) => {
      state.ukgMappingItems = items;
    },
    SET_UKG_MAPPING_ITEM_BY_BRAND: (state, items) => {
      state.ukgMappingByBrand = items;
    }
  }
};
