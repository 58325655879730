export default {
  namespaced: true,
  state: {},
  actions: {
    fetchLogs: async ({ dispatch }, { jobId, currentPage, selectedSize }) => {
      const requestParams = { path: `logs/${jobId}?page=${currentPage - 1}&size=${selectedSize}`,  method: 'GET' };
      const res = await dispatch('apiRequest', requestParams, { root: true });
      return res.json();
    }
  }
};
