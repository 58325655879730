const defaultCompanyTypeMapping = {
  id: null,
  company: null,
  companyCode: null,
  companyType: null,
  daysDelay: null,
  uploadedDate: null
};

export default {
  namespaced: true,
  state: () => ({
    companyTypeMappingItems: [],
    bulkUploadConfig: {
      name: 'Company Type Mapping',
      uploadUrl: '/uploads/mapping/company-type',
      downloadUrl: '/uploads/mapping/download/company-type'
    },
    companyTypeMapping: {
      id: null,
      company: null,
      companyCode: null,
      companyType: null,
      daysDelay: null,
      uploadedDate: null
    }
  }),
  getters: {
    companyTypeMappingItems: (state) => state.companyTypeMappingItems,
    bulkUploadConfig: (state) => state.bulkUploadConfig,
    getCompanyTypeMapping: (state) => state.companyTypeMapping
  },
  actions: {
    fetchCompanyTypeMappingItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'company-type-mapping', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_COMPANY_TYPE_MAPPING_ITEMS', rows);
    },
    fetchCompanyTypeMappingItemById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `company-type-mapping/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    createItem: async ({ dispatch }, item) => {
      const params = {
        path: 'company-type-mapping',
        method: 'POST',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    updateItem: async ({ dispatch }, item) => {
      const params = {
        path: `company-type-mapping/${item.id}`,
        method: 'PUT',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    deleteItem: async ({ dispatch }, id) => {
      const params = {
        path: `company-type-mapping/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
    uploadCompanyTypeMappingFile: async ({ dispatch }, data) => {
      const params = {
        path: 'uploads/mapping/company-type',
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchCompanyTypeByCompany: async ({ dispatch, commit }, brandId) => {
      const params = {
        path: `company-type-mapping/company/${brandId}`,
        method: 'GET'
      };
      try {
        const res = await dispatch('apiRequest', params, { root: true });
        const rows = await res.json();
        commit('SET_COMPANY_TYPE', rows);
      } catch (error) {
        if (error.cause.errorDetails.status === 404) {
          commit('SET_COMPANY_TYPE', { ...defaultCompanyTypeMapping });
        } else {
          throw error;
        }
      }
    }
  },
  mutations: {
    SET_COMPANY_TYPE_MAPPING_ITEMS: (state, items) => {
      state.companyTypeMappingItems = items;
    },
    SET_COMPANY_TYPE: (state, item) => {
      state.companyTypeMapping = item;
    }
  }
};
