export default {
  namespaced: true,
  state: () => ({
    users: []
  }),
  getters: {
    users: (state) => state.users
  },
  actions: {
    fetchUsers: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'user', method: 'GET' }, { root: true });
      const items = await res.json();
      commit('SET_USERS', items);
    },
    fetchUserById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `user/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    updateUser: async ({ dispatch }, data) => {
      const params = {
        path: `user/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    createUser: async ({ dispatch }, data) => {
      const params = {
        path: 'user/',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteUser: async ({ dispatch }, id) => {
      const params = {
        path: `user/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
    resetPassword: async ({ dispatch }, data) => {
      const params = {
        path: `user/reset-password/${data.id}`,
        method: 'PUT',
        body: JSON.stringify(
          { password: data.password }
        )
      };
      await dispatch('apiRequest', params, { root: true });
    },
    activateUser: async ({ dispatch }, id) => {
      const params = {
        path: `user/${id}/activate`,
        method: 'PUT'
      };
      await dispatch('apiRequest', params, { root: true });
    }
  },
  mutations: {
    'SET_USERS': (state, items) => {
      state.users = items;
    }
  }
};
