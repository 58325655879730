export default {
  namespaced: true,
  state: {
    invoices: []
  },
  getters: {
    invoices: (state) => state.invoices
  },
  actions: {
    uploadInvoiceFile: async ({ dispatch }, formData) => {
      const params = {
        path: 'uploads/invoice',
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': undefined
        }
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return res.json();
    },
    fetchInvoices: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', {
        path: 'uploads/invoice',
        method: 'GET'
      }, { root: true });
      const rows = await res.json();
      commit('SET_INVOICES', rows);
    },
  },
  mutations: {
    SET_INVOICES: (state, items) => {
      state.invoices = items;
    }
  }
};
