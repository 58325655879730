import { createRouter, createWebHashHistory } from 'vue-router';
import { validateRouteAccess } from '@/router/routeAccessValidator';
import { routes } from '@/router/routes';
import store from '@/vuex';

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {

  const userRole = store.getters['auth/getUserRole'];
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  const isRouteAccessibleForRole = validateRouteAccess(to.name, userRole);

  if (to.path !== '/auth' && !isAuthenticated) {
    store.dispatch('auth/setRedirectPath', to.path);
    next({ name: 'auth' });
    return;
  } else if (!isRouteAccessibleForRole) {
    next({ name: 'error', params: { code: 403 }});
    return;
  } else {
    next();
  }
});

export default router;

