export default {
  namespaced: true,
  state: () => ({
    tSheetsMappingItems: [],
    bulkUploadConfig: {
      name: 'TSheets Mapping',
      uploadUrl: '/uploads/mapping/tsheets',
      downloadUrl: '/uploads/mapping/download/tsheets'
    }
  }),
  getters: {
    tSheetsMappingItems: (state) => state.tSheetsMappingItems,
    bulkUploadConfig: (state) => state.bulkUploadConfig
  },
  actions: {
    fetchTSheetsMappingItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'tsheets-mapping', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_T_SHEETS_MAPPING_ITEMS', rows);
    },
    fetchTSheetsMappingItemById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `tsheets-mapping/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    createItem: async ({ dispatch }, item) => {
      const params = {
        path: 'tsheets-mapping',
        method: 'POST',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    updateItem: async ({ dispatch }, item) => {
      const params = {
        path: `tsheets-mapping/${item.id}`,
        method: 'PUT',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    deleteItem: async ({ dispatch }, id) => {
      const params = {
        path: `tsheets-mapping/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    }
  },
  mutations: {
    SET_T_SHEETS_MAPPING_ITEMS: (state, items) => {
      state.tSheetsMappingItems = items;
    }
  }
};
