export default {
  namespaced: true,
  state: () => ({
    userRoles: [],
    userPrivileges: []
  }),
  getters: {
    userRoles: (state) => state.userRoles,
    userPrivileges: (state) => state.userPrivileges,
  },
  actions: {
    fetchUserRoles: async ({ dispatch, commit }) => {
      const params = {
        path: 'role',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_USER_ROLES', await response.json());
    },
    fetchUserPrivileges: async ({ dispatch, commit }) => {
      const params = {
        path: 'role/privilege',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_USER_PRIVILEGES', await response.json());
    }
  },
  mutations: {
    'SET_USER_ROLES': (state, userRoles) => {
      state.userRoles = userRoles;
    },
    'SET_USER_PRIVILEGES': (state, privileges) => {
      state.userPrivileges = privileges;
    }
  }
};
