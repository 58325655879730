export default {
  namespaced: true,
  state: {},
  actions: {
    fetchAllLogs: async ({ dispatch }, { currentPage, selectedSize, queryString }) => {
      const requestParams = { path: `auditlog?page=${currentPage - 1}&size=${selectedSize}${queryString}`, method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return response.json();
    },
    fetchLog: async ({ dispatch }, jobId) => {
      const requestParams = { path: `auditlog/${jobId}`, method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return await response.json();
    },
    fetchPreviousLog: async ({ dispatch }, jobId) => {
      const requestParams = { path: `auditlog/previous/${jobId}`, method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return await response.json();
    },
    fetchFilters: async ({ dispatch }) => {
      const requestParams = { path: 'auditlog/filters', method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return await response.json();
    }
  }
};
