<template>
  <li
    class="nav-item"
  >
    <component
      :is="renderedComponent"
      class="nav-link"
      :to="route"
      :class="{active: isActive}"
      :target="target"
      :href="href"
    >
      <i
        ref="tooltip-toggler"
        :class="iconClass"
        :title="tooltipText"
        data-bs-toggle="tooltip"
      />
      <span
        v-if="showText"
        class="p-2"
      >
        {{ itemName }}
      </span>
    </component>
  </li>
</template>
<script>
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'isActive', 'iconClass', 'itemName', 'route', 'tooltipText', 'showText', 'href', 'target' ],
  data() {
    return {
      tooltip: null,
    };
  },
  computed: {
    renderedComponent() {
      return this.href ? 'a' : 'router-link';
    },
  },
  mounted() {
    this.tooltip = new Tooltip(this.$refs['tooltip-toggler']);
  }
};
</script>
