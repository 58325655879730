export default {
  namespaced: true,
  state: () => ({
    brandDashboards: [],
  }),
  getters: {
    brandDashboards: (state) => state.brandDashboards
  },
  actions: {
    fetchDashboardsForBrand: async ({ dispatch, commit }, brandId) => {
      commit('SET_DASHBOARDS', []);
      const res = await dispatch('apiRequest', { path: `dashboards/brand/${brandId}`, method: 'GET' }, { root: true });
      const items = await res.json();
      commit('SET_DASHBOARDS', items);
      return items;
    },
    clearStore: ({ commit }) => {
      commit('SET_DASHBOARDS', []);
    },
    fetchAllDashboards: async ({ dispatch }) => {
      const res = await dispatch('apiRequest', { path: 'dashboards/', method: 'GET' }, { root: true });
      return res.json();
    },
    fetchBrandDashboardDataById: async ({ dispatch }, dashboardId) => {
      const params = {
        path: `dashboards/${dashboardId}`,
        method: 'GET'
      };
      const res = await dispatch('apiRequest', params, { root: true });
      return await res.json();
    },
    updateBrandDashboardData: async ({ dispatch }, { dashboardItem, dashboardId }) => {
      const params = {
        path: `dashboards/${dashboardId}`,
        method: 'PUT',
        body: JSON.stringify(dashboardItem)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    createDashboardData: async ({ dispatch }, data) => {
      const params = {
        path: 'dashboards',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteBrandDashboardData: async ({ dispatch }, dashboardId) => {
      const params = {
        path: `dashboards/${dashboardId}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    }
  },
  mutations: {
    'SET_DASHBOARDS': (state, items) => {
      state.brandDashboards = items;
    }
  }
};
