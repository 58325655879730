export default {
  namespaced: true,
  state: () => ({
    agencyItems: [],
    bulkUploadConfig: {
      name: 'Agency Configuration',
      uploadUrl: '/agency/upload',
      downloadUrl: '/agency/download'
    },
  }),
  getters: {
    agencyItems: (state) => state.agencyItems,
    bulkUploadConfig: (state) => state.bulkUploadConfig
  },
  actions: {
    fetchAgencyItems: async ({ dispatch, commit }) => {
      const res = await dispatch('apiRequest', { path: 'agency', method: 'GET' }, { root: true });
      const rows = await res.json();
      commit('SET_AGENCY_ITEMS', rows);
    },
    fetchAgencyItemById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `agency/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    createItem: async ({ dispatch }, item) => {
      const params = {
        path: 'agency',
        method: 'POST',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    updateItem: async ({ dispatch }, item) => {
      const params = {
        path: `agency/${item.id}`,
        method: 'PUT',
        body: JSON.stringify(item)
      };

      await dispatch('apiRequest', params, { root: true });
    },
    deleteItem: async ({ dispatch }, id) => {
      const params = {
        path: `agency/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    }
  },
  mutations: {
    SET_AGENCY_ITEMS: (state, items) => {
      state.agencyItems = items;
    }
  }
};
