<template>
  <div class="container-fluid p-0">
    <div class="d-flex flex-row">
      <div>
        <Header
          v-if="isAuthenticated"
        />
      </div>
      <div class="main-content__container">
        <div>
          <router-view :key="$route.fullPath" />
          <Footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OuterspaceAdmin',
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  }
};
</script>

<style scoped>
.main-content__container {
  overflow: auto;
  max-height: 100vh;
  width: 100%;
}

@font-face {
  font-family: Roobert-Regular;
  src: url("../public/fonts/roobert-regular.woff2");
}

@font-face {
  font-family: Roobert-Semi-Bold;
  src: url("../public/fonts/roobert-semi-bold.woff2");
}

@font-face {
  font-family: HW-Cigars-Regular;
  src: url("../public/fonts/hw-cigars-regular.woff2");
}
</style>
