<template>
  <Collapsible
    :isCollapsed="!isExpanded"
  >
    <template #item-header>
      <i
        ref="tooltip-toggler"
        :class="iconClass"
        :title="tooltipText"
        data-bs-toggle="tooltip"
      />
      <span
        v-if="showText"
        class="p-2"
      >
        {{ itemName }}
      </span>
    </template>
    <template #item-content>
      <ul class="navbar-nav me-auto mb-0">
        <slot name="section-content" />
      </ul>
    </template>
  </Collapsible>
</template>
<script>
import Collapsible from '@/components/common/Collapsible.vue';
import { Tooltip } from 'bootstrap';

export default {
  components: {
    Collapsible
  },
  props: [ 'iconClass', 'itemName', 'tooltipText', 'showText', 'isExpanded' ],
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    this.tooltip = new Tooltip(this.$refs['tooltip-toggler']);
  },
};
</script>
