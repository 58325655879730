export default {
  namespaced: true,
  state: {
    allJobs: []
  },
  actions: {
    createJob: async ({ dispatch }, job) => {
      const body = { ...job };
      if (body?.customParams?.brand === null) {
        delete body.customParams.brand;
      }
      if (body?.customParams?.shiphero === null) {
        delete body.customParams.shiphero;
      }
      const requestParams = { path: 'scheduler/job', method: 'POST', body: JSON.stringify(body) };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return response.json();
    },
    updateJob: async ({ dispatch }, job) => {
      const body = { ...job };
      if (body?.customParams?.brand === null) {
        delete body.customParams.brand;
      }
      if (body?.customParams?.shiphero === null) {
        delete body.customParams.shiphero;
      }
      const requestParams = { path: `scheduler/job/${job.id}`, method: 'PUT', body: JSON.stringify(body) };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      return response.json();
    },
    updateJobStatus: async ({ dispatch }, { jobId, status }) => {
      const requestParams = { path: `scheduler/job/${jobId}/${status}`, method: 'PUT' };
      return dispatch('apiRequest', requestParams, { root: true });
    },
    startJob: async ({ dispatch }, jobId) => {
      const requestParams = { path: `scheduler/job/${jobId}/now`,  method: 'GET' };
      return dispatch('apiRequest', requestParams, { root: true });
    },
    deleteJob: async ({ dispatch }, jobId) => {
      const requestParams = { path: `scheduler/job/${jobId}`,  method: 'DELETE' };
      return dispatch('apiRequest', requestParams, { root: true });
    },
    fetchJob: async ({ dispatch }, jobId) => {
      const requestParams = { path: `scheduler/job/${jobId}`,  method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });
      const jobData = await response.json();
      if (jobData.customParams === undefined) {
        jobData.customParams = {};
      }
      jobData.customParams =
        { brand: null, shiphero: null, ...jobData.customParams };
      return jobData;
    },
    fetchAllJobs: async ({ dispatch, commit }) => {
      const requestParams = { path: 'scheduler/job',  method: 'GET' };
      const response = await dispatch('apiRequest', requestParams, { root: true });

      const data = await response.json();
      commit('SET_ALL_JOBS', data);
    }
  },
  getters: {
    jobs: (state) => state.allJobs
  },
  mutations: {
    SET_ALL_JOBS: (state, jobs) => {
      state.allJobs = jobs;
    }
  }
};
