export default {
  namespaced: true,
  state: () => ({
    userProfile: {}
  }),
  getters: {
    userProfile: (state) => state.userProfile
  },
  actions: {
    fetchUserProfile:  async ({ dispatch, commit }) => {
      const params = {
        path: 'profile',
        method: 'GET'
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_USER_PROFILE', await response.json());
    },
    updateUserPassword:  async ({ dispatch }, body) => {
      const params = {
        path: 'profile/password',
        method: 'POST',
        body: JSON.stringify(body)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    configureMFA: async ({ dispatch, commit }) => {
      const params = {
        path: 'configureMFA',
        method: 'PUT',
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_USER_PROFILE', await response.json());
    },
    updateMFAConfiguration: async ({ dispatch, commit }, isUsingMFA) => {
      const params = {
        path: 'updateMFA',
        method: 'PUT',
        body: JSON.stringify({ isUsingMFA })
      };
      const response = await dispatch('apiRequest', params, { root: true });
      commit('SET_USER_PROFILE', await response.json());
    }
  },
  mutations: {
    'SET_USER_PROFILE': (state, userProfile) => {
      state.userProfile = userProfile;
    }
  }
};
