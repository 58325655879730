import _ from 'lodash';
import { createStore } from 'vuex';
import config from '@/config';
import createPersistedState from 'vuex-persistedstate';
import router from '@/router';

// import modules
import sla from './sla';
import tSheetsMapping from './tSheetsMapping';
import companyTypeMapping from './companyTypeMapping';
import service from './service';
import scheduler from './scheduler';
import userManagement from './userManagement';
import userProfile from './userProfile';
import adminActions from '@/vuex/adminActions';
import invoicesUpload from './invoicesUpload';
import dashboard  from './dashboard';
import forecast from './forecast';
import auth from './auth';
import ukgMapping from './ukgMapping';
import jobLogs from './jobLogs';
import customerPortalUsersManagement from './customerPortalUsersManagement';
import dashboards from './dashboards';
import brandManagement from './brandManagement';
import agency from './agency';
import laborPlanning from './laborPlanning';
import auditLog from './auditLog';
import userRole from '@/vuex/userRole';

const buildHeaders = (customHeaders, token) => {
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  if (!_.isEmpty(customHeaders)) {
    headers = {
      ...headers,
      ...customHeaders
    };
  }

  if ([ null, undefined ].includes(headers['Content-Type'])) {
    delete headers['Content-Type'];
  }

  return headers;
};

const store = createStore({
  actions: {
    apiRequest: async (context, { path, method, body, headers }) => {
      const token = context.getters['auth/getToken'];
      const result = await fetch(
        `${config.apiBase}/${path}`,
        {
          method,
          body,
          mode: 'cors',
          headers: buildHeaders(headers, token)
        }
      );

      if (result.status === 401 && router.currentRoute.value.name !== 'auth') {
        await context.dispatch('auth/logOut');
        router.push({ to: '/auth', name: 'auth' });
      }

      // status codes 4xx  and 5xx are error codes, so we would to throw an Error
      if (result.status >= 400 && result.status < 600) {
        let errorDetails = {};
        const errorDetailsText =  await result.text();

        try {
          errorDetails = JSON.parse(errorDetailsText);
        } catch (e) {
          errorDetails.message = errorDetailsText;
        }
        errorDetails.status = result.status;
        const error = new Error(errorDetails.message);
        error.cause = { type: 'http', errorDetails };
        throw error;
      }
      return result;
    }

  },
  modules: {
    sla,
    tSheetsMapping,
    companyTypeMapping,
    service,
    scheduler,
    userManagement,
    userProfile,
    adminActions,
    dashboard,
    invoicesUpload,
    forecast,
    auth,
    ukgMapping,
    jobLogs,
    customerPortalUsersManagement,
    dashboards,
    brandManagement,
    agency,
    laborPlanning,
    auditLog,
    userRole,
  },
  plugins: [
    createPersistedState({
      paths: ['auth.token']
    })
  ]
});

export default store;
