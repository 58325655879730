export default {
  namespaced: true,
  state: {
    serviceStatuses: []
  },
  actions: {
    changeServiceStatus: async ({ dispatch }, payload) => {
      const params = {
        path: 'admin-actions/service-status',
        method: 'PUT',
        body: JSON.stringify(payload)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    changeServiceNotificationMessage: async ({ dispatch }, payload) => {
      const params = {
        path: 'admin-actions/service-status-notification-message',
        method: 'PUT',
        body: JSON.stringify(payload)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    fetchServiceStatuses: async ({ dispatch, commit }) => {
      const params = {
        path: 'admin-actions/service-statuses',
        method: 'GET',
      };
      const response = await dispatch('apiRequest', params, { root: true });
      const statuses = await response.json();
      commit('SET_SERVICE_STATUSES', statuses);
    }
  },
  getters: {
    serviceStatuses: (state) => state.serviceStatuses
  },
  mutations: {
    'SET_SERVICE_STATUSES': (state, statuses) => {
      state.serviceStatuses = statuses;
    }
  }
};
