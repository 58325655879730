import { createRemoteJWKSet, jwtVerify } from 'jose';
import config from '@/config';
const JWKS = createRemoteJWKSet(new URL(`${config.apiBase}/public-key`));

export const parseToken = async (token) => {
  try {
    const { payload } = await jwtVerify(token, JWKS, {
      issuer: 'outerspace',
      audience: 'outerspace-services',
    });
    return payload;
  } catch (e) {
    return undefined;
  }
};
