<template>
  <div class="row text-center m-0 mt-5">
    <p class="text-muted copyright" />
  </div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped></style>
