export default {
  namespaced: true,
  state: () => ({
    users: []
  }),
  getters: {
    users: (state) => state.users
  },
  actions: {
    fetchUsers: async ({ dispatch, commit }, brandId) => {
      commit('SET_USERS', []);
      let requestUrl = 'customer-portal-user/users';
      if (brandId) {
        requestUrl += `?brandId=${brandId}`;
      }
      const res = await dispatch('apiRequest', { path: requestUrl, method: 'GET' }, { root: true });
      const items = await res.json();
      commit('SET_USERS', items);
    },
    fetchUserById: async ({ dispatch }, id) => {
      const res = await dispatch('apiRequest', { path: `customer-portal-user/user/${id}`, method: 'GET' }, { root: true });
      return res.json();
    },
    updateUser: async ({ dispatch }, data) => {
      const params = {
        path: `customer-portal-user/user/${data.userId}`,
        method: 'PUT',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    createUser: async ({ dispatch }, data) => {
      const params = {
        path: 'customer-portal-user/user',
        method: 'POST',
        body: JSON.stringify(data)
      };
      await dispatch('apiRequest', params, { root: true });
    },
    deleteUser: async ({ dispatch }, id) => {
      const params = {
        path: `customer-portal-user/user/${id}`,
        method: 'DELETE'
      };
      await dispatch('apiRequest', params, { root: true });
    },
  },
  mutations: {
    'SET_USERS': (state, items) => {
      state.users = items;
    }
  }
};
