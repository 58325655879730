import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';

import App from './App.vue';
import router from './router';
import store from './vuex';

import '@popperjs/core';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

(async () => {
  await store.dispatch('auth/initAuth');
  createApp(App)
    .use(router)
    .use(store)
    .use(Toaster)
    .mount('#outerspace-admin');
})();

